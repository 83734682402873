module.exports = [{
      plugin: require('/Users/samlarsen-disney/Documents/sld.codes/sld-wall/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/mango.png"},
    },{
      plugin: require('/Users/samlarsen-disney/Documents/sld.codes/sld-wall/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/samlarsen-disney/Documents/sld.codes/sld-wall/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
